import router from "@/router";
import {getError} from "@/utils/helpers";
import AuthService from "@/services/AuthService";

export const namespaced = true;

export const state = {
    user: null,
    loading: true,
    error: null,
    loginLoadStatus: 0,
    logoutLoadStatus: 0,
    resetPasswordLoadStatus: 0,
    resetPasswordStatus: 0,
    forgotPasswordStatus: 0,

    userPermissions: null,
    userPermissionStatus: 0,

    responseError: null,
    verifyEmailStatus: 0,
    verifyEmailResponse: null,
    verifyEmailExpired: false,
    verifyEmailResendStatus: 0,

    changeUserEmailStatus: 0,
    changeUserPasswordStatus: 0,
    updateAccountSettingStatus: 0,
    readAccountSettingStatus: 0,
    accountSettings: 0
};

export const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },


    SET_LOGIN_LOAD_STATUS(state, status) {
        state.loginLoadStatus = status;
    },
    SET_LOGOUT_LOAD_STATUS(state, status) {
        state.logoutLoadStatus = status;
    },
    SET_RESET_PASSWORD_LOAD_STATUS(state, status) {
        state.resetPasswordLoadStatus = status;
    },
    SET_RESET_PASSWORD_STATUS(state, status) {
        state.resetPasswordStatus = status;
    },
    SET_FORGOT_PASSWORD_STATUS(state, status) {
        state.forgotPasswordStatus = status;
    },
    SET_RESPONSE_ERROR(state, error){
        state.responseError = error;
    },
    SET_VERIFY_EMAIL_STATUS(state, status){
        state.verifyEmailStatus = status;
    },
    SET_VERIFY_EMAIL_RESPONSE(state, response){
        state.verifyEmailResponse = response;
    },
    SET_VERIFY_EMAIL_EXPIRED(state, condition){
        state.verifyEmailExpired = condition;
    },
    SET_VERIFY_EMAIL_RESEND_STATUS(state, status){
        state.verifyEmailResendStatus = status;
    },

    SET_USER_PERMISSION_STATUS(state, status) {
        state.userPermissionStatus = status;
    },

    SET_USER_PERMISSIONS(state, permissions) {
        state.userPermissions = permissions;
    },

    SET_CHANGE_USER_EMAIL_STATUS(state, status) {
        state.changeUserEmailStatus = status;
    },

    SET_CHANGE_USER_PASSWORD_STATUS(state, status) {
        state.changeUserPasswordStatus = status;
    },

    SET_UPDATE_ACCOUNT_SETTINGS_STATUS(state, status) {
        state.updateAccountSettingStatus = status;
    },

    SET_READ_ACCOUNT_SETTINGS_STATUS(state, status) {
        state.readAccountSettingStatus = status;
    },

    SET_ACCOUNT_SETTINGS(state, data) {
        state.accountSettings = data;
    },
};

export const actions = {
    async getAuthUser({commit}) {
        commit("SET_LOADING", true);
        try {
            const response = await AuthService.getAuthUser();
            commit("SET_USER", response.data.data);
            commit("SET_LOADING", false);
            commit("SET_RESPONSE_ERROR", null);
            return response.data.data;
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_USER", null);
            commit("SET_ERROR", getError(error));
            commit("SET_RESPONSE_ERROR", error);
        }
    },

    async updateUserSettings({ commit }, settings) {
        try {
            const response = await AuthService.updateUserSettings(settings);
            commit("SET_USER", response.data.data);
        } catch (error) {
            console.error("Error updating user settings:", error);
        }
    },

    setGuest(context, {value}) {
        window.localStorage.setItem("guest", value);
    },

    initLogin({commit}) {
        commit("SET_LOGIN_LOAD_STATUS", 0);
    },
    initLogout({commit}) {
        commit("SET_LOGOUT_LOAD_STATUS", 0);
    },

    initResetPassword({commit}) {
        commit('SET_RESET_PASSWORD_LOAD_STATUS', 0);
        commit('SET_RESET_PASSWORD_STATUS', 0);
    },

    async login({commit}, data) {
        commit('SET_LOGIN_LOAD_STATUS', 1);
        try {
            await AuthService.login(data);
            const authUser = await this.getAuthUser();

            if (authUser) {
                commit('SET_LOGIN_LOAD_STATUS', 2);
                // dispatch("setGuest", { value: "isNotGuest" });
                this.setGuest({ value: "isNotGuest" });
                // router.push("/dashboard");
            } else {
                const error = Error("Unable to fetch user after login, check your API settings.");
                error.name = "Fetch User";
                commit('SET_LOGIN_LOAD_STATUS', 3);
                commit('SET_ERROR', getError(error));
            }
        } catch (error) {
            commit('SET_LOGIN_LOAD_STATUS', 3);
            commit('SET_ERROR', getError(error));
            throw error;
        }
    },

    logout({commit, dispatch}) {
        commit('SET_LOGOUT_LOAD_STATUS', 1);
        return AuthService.logout().then(() => {
            commit('SET_LOGOUT_LOAD_STATUS', 2);
            commit("SET_USER", null);
            dispatch("setGuest", {value: "isGuest"});
            if (router.currentRoute.name !== "login") {
                router.push({path: "/login"});
            }
        }).catch((error) => {
            commit('SET_LOGOUT_LOAD_STATUS', 3);
            commit("SET_ERROR", getError(error));
        });
    },

    resetPassword({commit}, data) {
        commit('SET_RESET_PASSWORD_STATUS', 1);
        AuthService.resetPassword(data)
            .then(() => {
                commit('SET_RESET_PASSWORD_STATUS', 2);
            })
            .catch((error) => {
                console.log("CAUGHT AUTHSERVICE ERROR");
                console.log(error);
                commit('SET_RESET_PASSWORD_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },

    forgotPassword({ commit }, data) {
        commit('SET_FORGOT_PASSWORD_STATUS', 1);
        AuthService.forgotPassword(data).then(() => {
            commit('SET_FORGOT_PASSWORD_STATUS', 2);
        }).catch((error) => {
            commit('SET_FORGOT_PASSWORD_STATUS', 3);
            commit('SET_ERROR', getError(error));
        });
    },

    async getUserPermissions({ commit }) {
        commit('SET_USER_PERMISSION_STATUS', 1);

        try {
            const response = await AuthService.getUserPermissions();
            commit('SET_USER_PERMISSION_STATUS', 2);
            commit("SET_USER_PERMISSIONS", response.data);
            //if user has only one permission, redirect to that view
            if (response.data.Permissions && Object.keys(response.data.Permissions).length === 1) {
                const onePermission = Object.keys(response.data.Permissions)[0];
                router.push(`/${onePermission}`);
            }
            else if (router.currentRoute._value.path == "/login" || router.currentRoute._value.path == "/") {
                if (router.currentRoute._value.query?.redirect != undefined){
                    router.push(router.currentRoute._value.query.redirect);
                }
                else {
                    router.push("/dashboard");
                }                
            }
            return response.data.data;
        } catch(error) {
            commit('SET_USER_PERMISSION_STATUS', 3);
            commit('SET_ERROR', getError(error));
        }
    },
    verifyEmail({ commit }, url) {
        commit('SET_VERIFY_EMAIL_STATUS', 1);
        AuthService.verifyEmail(url).then((response) => {
            commit('SET_VERIFY_EMAIL_RESPONSE', response.data);
            commit('SET_VERIFY_EMAIL_STATUS', 2);
        }).catch((error) => {
            commit('SET_VERIFY_EMAIL_RESPONSE', null);
            console.log(error.response);
            // eslint-disable-next-line no-prototype-builtins
            if(error.response.data.hasOwnProperty('message')){
                if(error.response.data.message === "Invalid/Expired url provided." ){
                    commit('SET_VERIFY_EMAIL_EXPIRED', true);
                }
            }
            commit('SET_VERIFY_EMAIL_STATUS', 3);
            commit('SET_ERROR', getError(error));
        });
    },
    setInitialPassword({commit, getters}, data) {
        let verifyEmailResponse = getters.getVerifyEmailResponse;
        let payload = {
            email: verifyEmailResponse.email,
            password: data.password,
            password_confirmation: data.password_confirmation,
            token: verifyEmailResponse.token
        };
        commit('SET_RESET_PASSWORD_STATUS', 1);
        AuthService.resetPassword(payload)
            .then(() => {
                commit('SET_RESET_PASSWORD_STATUS', 2);
                commit('SET_VERIFY_EMAIL_RESPONSE', null);
            })
            .catch((error) => {
                commit('SET_RESET_PASSWORD_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },
    resendEmailVerification({commit}, email) {
        commit('SET_VERIFY_EMAIL_RESEND_STATUS', 1);
        AuthService.resendVerifyEmail(email)
            .then(() => {
                commit('SET_VERIFY_EMAIL_RESEND_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_VERIFY_EMAIL_RESEND_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },
    changeUserEmail({commit}, payload) {
        commit('SET_CHANGE_USER_EMAIL_STATUS', 1);
        AuthService.changeUserEmail(payload)
            .then(() => {
                commit('SET_CHANGE_USER_EMAIL_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_CHANGE_USER_EMAIL_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },
    changeUserPassword({commit}, payload) {
        commit('SET_CHANGE_USER_PASSWORD_STATUS', 1);
        AuthService.changeUserPassword(payload)
            .then(() => {
                commit('SET_CHANGE_USER_PASSWORD_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_CHANGE_USER_PASSWORD_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },
    updateAccountSettings({commit}, payload) {
        commit('SET_UPDATE_ACCOUNT_SETTINGS_STATUS', 1);
        AuthService.updateAccountSettings(payload)
            .then((response) => {
                commit('SET_ACCOUNT_SETTINGS', response.data.data);
                commit('SET_UPDATE_ACCOUNT_SETTINGS_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_UPDATE_ACCOUNT_SETTINGS_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },
    readAccountSettings({commit}) {
        commit('SET_READ_ACCOUNT_SETTINGS_STATUS', 1);
        AuthService.readAccountSettings()
            .then((response) => {
                commit('SET_ACCOUNT_SETTINGS', response.data.data);
                commit('SET_READ_ACCOUNT_SETTINGS_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_READ_ACCOUNT_SETTINGS_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },
};

export const getters = {
    authUser: (state) => {
        return state.user;
    },
    isGlobal: (state) => {
        if (state.userPermissions?.Permissions?.Global?.access == undefined) {
        return false;
        }
    
        return state.userPermissions?.Permissions?.Global?.access;
    },
    isAdmin: (state) => {
        return state.user ? state.user.isAdmin : false;
    },
    error: (state) => {
        return state.error;
    },
    responseError: (state) => {
        return state.responseError;
    },
    loading: (state) => {
        return state.loading;
    },
    loggedIn: (state) => {
        return !!state.user;
    },
    guest: () => {
        const storageItem = window.localStorage.getItem("guest");
        if (!storageItem) {
            return false;
        }
        if (storageItem === "isGuest") {
            return true;
        }
        if (storageItem === "isNotGuest") {
            return false;
        }
    },


    getLoginLoadStatus(state) {
        return state.loginLoadStatus;
    },

    getLogoutLoadStatus(state) {
        return state.logoutLoadStatus;
    },

    getResetPasswordLoadStatus(state) {
        return state.resetPasswordLoadStatus;
    },
    getResetPasswordStatus(state) {
        return state.resetPasswordStatus;
    },
    getForgotPasswordStatus(state) {
        return state.forgotPasswordStatus;
    },

    getUserPermissionStatus(state) {
        return state.userPermissionStatus;
    },

    userPermissions(state) {
        return state.userPermissions;
    },
    getVerifyEmailStatus(state){
        return state.verifyEmailStatus;
    },
    getVerifyEmailResponse(state){
        return state.verifyEmailResponse;
    },
    getEmailVerificationExpired(state){
        return state.verifyEmailExpired;
    },
    getVerifyEmailResendStatus(state){
        return state.verifyEmailResendStatus;
    },
    getChangeUserEmailStatus(state){
        return state.changeUserEmailStatus;
    },
    getChangeUserPasswordStatus(state){
        return state.changeUserPasswordStatus;
    },
    getUpdateAccountSettingStatus(state){
        return state.updateAccountSettingStatus;
    },
    getAccountSettings(state){
        return state.accountSettings;
    },
    getReadAccountSettingStatus(state){
        return state.readAccountSettingStatus;
    },

};

