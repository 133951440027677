import { getError } from "../../utils/helpers";
import AgentService from "../../services/AgentService";

export const namespaced = true;

function setPaginatedRecords( commit, response ) {
    commit("SET_RECORDS", response.data.data);
    commit("SET_META", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

export const state = () => ({
    records: [],
    record: null,
    loading: false,
    error: null,

    browseRecordStatus: 0,
    readRecordStatus: 0,
    editRecordStatus: 0,
    addRecordStatus: 0,
    deleteRecordStatus: 0,
    paginateRecordStatus: 0,
});

export const mutations = {
    SET_RECORDS(state, records) { state.records = records; },
    SET_RECORD(state, record) { state.record = record; },
    SET_META(state, meta) { state.meta = meta; },
    SET_LINKS(state, links) { state.links = links; },
    SET_LOADING(state, loading) { state.loading = loading; },
    SET_ERROR(state, error) { state.error = error; },
    SET_ADD_RECORD_STATUS(state, status) { state.addRecordStatus = status; },
    SET_EDIT_RECORD_STATUS(state, status) { state.editRecordStatus = status; },
    SET_DELETE_RECORD_STATUS(state, status) { state.deleteRecordStatus = status; },
    SET_BROWSE_RECORD_STATUS(state, status) { state.browseRecordStatus = status; },
    SET_READ_RECORD_STATUS(state, status) { state.readRecordStatus = status; },
    SET_PAGINATE_RECORD_STATUS(state, status) { state.paginateRecordStatus = status; }
};

export const getters = {
    records: (state) => state.records,
    record: (state) => state.record,
    loading: (state) => state.loading,
    error: (state) => state.error,

    browseRecordStatus: (state) => state.browseRecordStatus,
    readRecordStatus: (state) => state.readRecordStatus,
    editRecordStatus: (state) => state.editRecordStatus,
    addRecordStatus: (state) => state.addRecordStatus,
    deleteRecordStatus: (state) => state.deleteRecordStatus,
    paginateRecordStatus: (state) => state.paginateRecordStatus
};

export const actions = {
    browseRecords({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_RECORD_STATUS", 1);
        AgentService.browseRecords(payload)
            .then(response => {
                setPaginatedRecords(commit, response);
                commit("SET_BROWSE_RECORD_STATUS", 2);
            })
            .catch((error) => {
                commit("SET_BROWSE_RECORD_STATUS", 3);
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },

    paginateRecords({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_PAGINATE_RECORD_STATUS", 1);
        AgentService.paginateRecords(payload).then((response) => {
            commit("SET_PAGINATE_RECORD_STATUS", 2);
            setPaginatedRecords(commit, response);
        })
        .catch((error) => {
            commit("SET_PAGINATE_RECORD_STATUS", 3);
            commit("SET_LOADING", false);
            commit("SET_ERROR", getError(error));
        });
    },

    readRecord({ commit }, recordId) {
        commit("SET_ERROR", null);
        commit("SET_READ_RECORD_STATUS", 1);
        AgentService.readRecord(recordId)
            .then((response) => {
                commit("SET_RECORD", response.data.data);
                commit("SET_READ_RECORD_STATUS", 2);
            })
            .catch((error) => {
                commit("SET_READ_RECORD_STATUS", 3);
                commit("SET_RECORD", null);
                commit("SET_ERROR", getError(error));
            });
    },

    editRecord({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_EDIT_RECORD_STATUS", 1);
        AgentService.editRecord(payload)
            .then((response) => {
                commit("SET_RECORD", response.data.data);
                commit("SET_EDIT_RECORD_STATUS", 2);
            })
            .catch((error) => {
                commit("SET_EDIT_RECORD_STATUS", 3);
                commit("SET_ERROR", getError(error));
            });
    },

    addRecord({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_ADD_RECORD_STATUS", 1);
        AgentService.addRecord(payload)
            .then((response) => {
                commit("SET_RECORD", response.data.data);
                commit("SET_ADD_RECORD_STATUS", 2);
            })
            .catch((error) => {
                commit("SET_ADD_RECORD_STATUS", 3);
                commit("SET_ERROR", getError(error));
            });
    },

    deleteRecord({ commit }, id) {
        commit("SET_ERROR", null);
        commit("SET_DELETE_RECORD_STATUS", 1);
        AgentService.deleteRecord(id)
            .then(() => {
                commit("SET_DELETE_RECORD_STATUS", 2);
            })
            .catch((error) => {
                commit("SET_DELETE_RECORD_STATUS", 3);
                commit("SET_ERROR", getError(error));
            });
    },

    initRecords({ commit }) {
        commit("SET_RECORDS", []);
        commit("SET_RECORD", {});

        commit("SET_BROWSE_RECORD_STATUS", 0);
        commit("SET_READ_RECORD_STATUS", 0);
        commit("SET_EDIT_RECORD_STATUS", 0);
        commit("SET_DELETE_RECORD_STATUS", 0);
        commit("SET_PAGINATE_RECORD_STATUS", 0);
    },

    dropdownSelected({ commit, getters }, agentId) {
        let records = JSON.parse(JSON.stringify(getters.records));
        if (agentId !== null && agentId !== undefined) {
            if (!records.some((agent) => agent.id === agentId)) {
                AgentService.readRecord(agentId).then((response) => {
                    records.push(response.data.data);
                    commit("SET_RECORDS", records);
                });
            }
        }
    },
};
