import * as API from "@/services/API";

const baseUrl = '/v1/roles/';

export default {
    browseRecords(payload) {
        var endpoint = baseUrl;
        if (payload !== undefined) {
            endpoint += '?';
            var params = new URLSearchParams(payload);
            endpoint += params.toString();
        }
        return API.apiClient.get(endpoint);
    },
    readRecord(recordId) {
        return API.apiClient.get(baseUrl + recordId);
    },
    editRecord(payload) {
        return API.apiClient.patch(baseUrl + payload.id, payload);
    },
    addRecord(payload) {
        return API.apiClient.post(baseUrl, payload);
    },
    browseRoleDispositions(payload) {
        var endpoint = baseUrl + `${payload.roleId}/dispositions/`;
        if (payload.roleId !== undefined) {
            endpoint += '?';
            var params = new URLSearchParams(payload.tableFilters);
            endpoint += params.toString();
        }
        return API.apiClient.get(endpoint);
    }, 
    browseRoleDispositionsAvailable(payload) {
        var endpoint = baseUrl + `${payload}/dispositions/available/`;
        if (payload.roleId !== undefined) {
            endpoint += '?';
            var params = new URLSearchParams(payload);
            endpoint += params.toString();
        }
        return API.apiClient.get(endpoint);
    }, 
    assignDispositionToRole(recordId, dispositionId) {
        return API.apiClient.post(baseUrl + `${recordId}/dispositions/${dispositionId}`);
    },  
    removeDispositionFromRole(recordId, dispositionId) {
        return API.apiClient.delete(baseUrl + `${recordId}/dispositions/${dispositionId}`);
    },
    browseRoleScorecards(payload) {
        var endpoint = baseUrl + `${payload.roleId}/scorecards/`;
        if (payload.roleId !== undefined) {
            endpoint += '?';
            var params = new URLSearchParams(payload.tableFilters);
            endpoint += params.toString();
        }
        return API.apiClient.get(endpoint);
    }, 
    browseRoleScorecardsAvailable(payload) {
        var endpoint = baseUrl + `${payload}/scorecards/available/`;
        if (payload.roleId !== undefined) {
            endpoint += '?';
            var params = new URLSearchParams(payload);
            endpoint += params.toString();
        }
        return API.apiClient.get(endpoint);
    }, 
    assignScorecardToRole(recordId, scorecardId) {
        return API.apiClient.post(baseUrl + `${recordId}/scorecards/${scorecardId}`);
    },  
    removeScorecardFromRole(recordId, scorecardId) {
        return API.apiClient.delete(baseUrl + `${recordId}/scorecards/${scorecardId}`);
    },
    deleteRecord(id) {
        return API.apiClient.delete(baseUrl + id);
    },
    paginateRecords(link) {
        return API.apiClient.get(link);
    },
    paginateRecordsAvailable(link) {
        return API.apiClient.get(link);
    },

}