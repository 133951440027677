import {getError} from "../../utils/helpers";
import LLMService from "../../services/LLMService";

export const namespaced = true;

function setPaginatedRecords( commit, response ) {
    commit("SET_RECORDS", response.data.data);
    commit("SET_META", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

export const state = () => ({
    records: [],
    record: null,
    meta: null,
    links: null,
    loading: false,
    error: null,
  
    browseRecordStatus: 0,
    readRecordStatus: 0,
    editRecordStatus: 0,
    addRecordStatus: 0,
    deleteRecordStatus: 0,
    paginateRecordStatus: 0,
    
    recordings: [],
    usage: null,
    data: [],
    log: [],
    actions: [],
    transcript: null,
    quote: null,
    uuid: null,
  
    getRecordingsStatus: 0,
    getUsageStatus: 0,
    getDataStatus: 0,
    getLogStatus: 0,
    getActionsStatus: 0,
    getTranscriptStatus: 0,
    getQuoteStatus: 0
});
  
export const mutations = {
    SET_RECORDS: (state, records) => state.records = records,
    SET_RECORD: (state, record) => state.record = record,
    SET_META: (state, meta) => state.meta = meta,
    SET_LINKS: (state, links) => state.links = links,
    SET_LOADING: (state, loading) => state.loading = loading,
    SET_ERROR: (state, error) => state.error = error,
    SET_ADD_RECORD_STATUS: (state, status) => state.addRecordStatus = status,
    SET_EDIT_RECORD_STATUS: (state, status) => state.editRecordStatus = status,
    SET_DELETE_RECORD_STATUS: (state, status) => state.deleteRecordStatus = status,
    SET_BROWSE_RECORD_STATUS: (state, status) => state.browseRecordStatus = status,
    SET_READ_RECORD_STATUS: (state, status) => state.readRecordStatus = status,
    SET_PAGINATION: (state, pagination) => state.pagination = pagination,
    SET_PAGINATE_RECORD_STATUS: (state, status) => state.paginateRecordStatus = status,
};
  
export const getters = {
    records: state => state.records,
    record: state => state.record,
    meta: state => state.meta,
    links: state => state.links,
    loading: state => state.loading,
    error: state => state.error,
    browseRecordStatus: state => state.browseRecordStatus,
    readRecordStatus: state => state.readRecordStatus,
    editRecordStatus: state => state.editRecordStatus,
    addRecordStatus: state => state.addRecordStatus,
    deleteRecordStatus: state => state.deleteRecordStatus,
    paginateRecordStatus: state => state.paginateRecordStatus
};  

export const actions = {
    browseRecords({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_RECORD_STATUS", 1);
        LLMService.browseRecords(payload).then((response) => {
            setPaginatedRecords(commit, response);
            commit("SET_BROWSE_RECORD_STATUS", 2);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_BROWSE_RECORD_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },
    readRecord({ commit }, recordId) {
        commit("SET_ERROR", null);
        commit("SET_READ_RECORD_STATUS", 1);
        LLMService.readRecord(recordId).then((response) => {
            commit("SET_READ_RECORD_STATUS", 2);
            commit("SET_RECORD", response.data.data);
        }).catch((error) => {
            commit("SET_READ_RECORD_STATUS", 3);
            commit("SET_RECORD", null);
            commit("SET_ERROR", getError(error));
        });
    },
    editRecord({commit}, payload) {
        commit("SET_ERROR", null);
        commit('SET_EDIT_RECORD_STATUS', 1);
        LLMService.editRecord(payload)
            .then((response) => {
                commit("SET_RECORD", response.data.data);
                commit('SET_EDIT_RECORD_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_EDIT_RECORD_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },
    addRecord({commit}, payload) {
        commit("SET_ERROR", null);
        commit('SET_ADD_RECORD_STATUS', 1);
        LLMService.addRecord(payload)
            .then((response) => {
                commit("SET_RECORD", response.data.data);
                commit('SET_ADD_RECORD_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_ADD_RECORD_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },
    deleteRecord({commit}, id) {
        commit("SET_ERROR", null);
        commit('SET_DELETE_RECORD_STATUS', 1);
        LLMService.deleteRecord(id)
            .then(() => {
                commit('SET_DELETE_RECORD_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_DELETE_RECORD_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },

    paginateRecords({ commit }, link) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_PAGINATE_RECORD_STATUS", 1);
        LLMService.paginateRecords(link).then((response) => {
            commit("SET_PAGINATE_RECORD_STATUS", 2);
            setPaginatedRecords(commit,response);
        }).catch((error) => {
            commit("SET_PAGINATE_RECORD_STATUS", 3);
            commit("SET_LOADING", false);
            commit("SET_ERROR", getError(error));
        });
    },

    initRecords({ commit }) {
        commit("SET_RECORDS", []);
        commit("SET_RECORD", {});
        commit("SET_PAGINATE_RECORD_STATUS", 0);
        commit("SET_BROWSE_RECORD_STATUS", 0);
        commit("SET_READ_RECORD_STATUS", 0);
        commit("SET_EDIT_RECORD_STATUS", 0);
        commit("SET_DELETE_RECORD_STATUS", 0);
    },

    dropdownSelected({commit, getters}, llmId){
        let records =  JSON.parse(JSON.stringify(getters['records']));
        if(llmId !== null && llmId !== undefined){
            if(!records.some(llm => llm.id === llmId)){
                LLMService.readRecord(llmId).then((response) => {
                    records.push(response.data.data);
                });
            }
        }
        commit("SET_RECORDS", records);
    }

};