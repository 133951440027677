import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/dispositions',
        name: 'dispositions',
        redirect: '/dispositions/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Disposition
        },
        component: ModelView,
        children: [
            {
                path: '',
                name: 'dispositions',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Dispositions',
                        }
                    ]
                },
                component: () => import('../../views/Dispositions/Index')
            },
            {
                path: "/dispositions/new",
                name: "newDisposition",
                meta: {
                    action: AUTH_HELPER.authActions.add,
                    breadcrumb: [
                        {
                            parent: 'Dispositions',
                            label: 'New',
                        }
                    ]
                },
                component: () => import('../../views/Dispositions/DispositionNew'),
            },
            {
                path: '/dispositions/:dispositionId',
                name: 'disposition',
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'Dispositions'
                        }
                    ]
                },
                component: () => import('../../views/Dispositions/Disposition')
            },
            {
                path: "/dispositions/:dispositionId/edit",
                name: "editDisposition",
                component: () => import('../../views/Dispositions/DispositionEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit,
                    breadcrumb: [
                        {
                            parent: 'Dispositions',
                            label: 'Edit',
                        }
                    ]
                },
            },
        ]
    }
];