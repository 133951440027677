import { getError } from "../../utils/helpers";
import RoleService from "../../services/RoleService";

export const namespaced = true;

function setPaginatedRecords(commit, response) {
    commit("SET_RECORDS", response.data.data);
    commit("SET_META", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

function setPaginatedDispositionsAvailable(commit, response) {
    commit("SET_ROLE_DISPOSITIONS_AVAILABLE", response.data.data);
    commit("SET_META_DISPOSITIONS_AVAILABLE", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

function setPaginatedScorecardsAvailable(commit, response) {
    commit("SET_ROLE_SCORECARDS_AVAILABLE", response.data.data);
    commit("SET_META_SCORECARDS_AVAILABLE", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

export const state = () => ({
    records: [],
    roleDispositionsAvailable: [],
    roleScorecardsAvailable: [],
    record: null,
    meta: null,
    metaDispositionsAvailable: null,
    metaScorecardsAvailable: null,
    links: null,
    loading: false,
    error: null,

    browseRecordStatus: 0,
    readRecordStatus: 0,
    editRecordStatus: 0,
    addRecordStatus: 0,
    deleteRecordStatus: 0,

    paginateRecordStatus: 0,
    paginateDispositionsAvailableStatus: 0,
    paginateScorecardssAvailableStatus: 0
});

export const mutations = {
    SET_RECORDS(state, records) {
        state.records = records;
    },

    SET_ROLE_DISPOSITIONS_AVAILABLE(state, roleDispositionsAvailable) {
        state.roleDispositionsAvailable = roleDispositionsAvailable;
    },

    SET_ROLE_SCORECARDS_AVAILABLE(state, roleScorecardsAvailable) {
        state.roleScorecardsAvailable = roleScorecardsAvailable;
    },

    SET_RECORD(state, record) {
        state.record = record;
    },

    SET_META(state, meta) {
        state.meta = meta;
    },

    SET_META_DISPOSITIONS_AVAILABLE(state, metaDispositionsAvailable) {
        state.metaDispositionsAvailable = metaDispositionsAvailable;
    },

    SET_META_SCORECARDS_AVAILABLE(state, metaScorecardsAvailable) {
        state.metaScorecardsAvailable = metaScorecardsAvailable;
    },

    SET_LINKS(state, links) {
        state.links = links;
    },

    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },

    SET_ADD_RECORD_STATUS(state, status) {
        state.addRecordStatus = status;
    },

    SET_EDIT_RECORD_STATUS(state, status) {
        state.editRecordStatus = status;
    },

    SET_DELETE_RECORD_STATUS(state, status) {
        state.deleteRecordStatus = status;
    },

    SET_BROWSE_RECORD_STATUS(state, status) {
        state.browseRecordStatus = status;
    },

    SET_READ_RECORD_STATUS(state, status) {
        state.readRecordStatus = status;
    },

    SET_PAGINATE_RECORD_STATUS(state, status) {
        state.paginateRecordStatus = status;
    },

    SET_PAGINATE_DISPOSITIONS_AVAILABLE_STATUS(state, status) {
        state.paginateDispositionsAvailableStatus = status;
    },

    SET_PAGINATE_SCORECARDS_AVAILABLE_STATUS(state, status) {
        state.paginateScorecardsAvailableStatus = status;
    },

    ASSIGN_DISPOSITION_TO_ROLE(state, { roleId, dispositionId }) {
        const role = state.dispositionsAvailable.find(r => r.id === roleId);
        if (role && !role.dispositions.includes(dispositionId)) {
            role.dispositions.push(dispositionId);
        }
    },

    REMOVE_DISPOSITION_FROM_ROLE(state, { roleId, dispositionId }) {
        const role = state.records.find(r => r.id === roleId);
        if (role) {
            role.dispositions = role.dispositions.filter(id => id !== dispositionId);
        }
    },

    ASSIGN_SCORECARD_TO_ROLE(state, { roleId, scorecardId }) {
        const role = state.scorecardsAvailable.find(r => r.id === roleId);
        if (role && !role.scorecards.includes(scorecardId)) {
            role.scorecards.push(scorecardId);
        }
    },

    REMOVE_SCORECARD_FROM_ROLE(state, { roleId, scorecardId }) {
        const role = state.records.find(r => r.id === roleId);
        if (role) {
            role.scorecards = role.scorecards.filter(id => id !== scorecardId);
        }
    }
};

export const getters = {
    records: state => state.records,
    roleDispositionsAvailable: state => state.roleDispositionsAvailable,
    roleScorecardsAvailable: state => state.roleScorecardsAvailable,
    record: state => state.record,
    meta: state => state.meta,
    metaDispositionsAvailable: state => state.metaDispositionsAvailable,
    metaScorecardsAvailable: state => state.metaScorecardsAvailable,
    links: state => state.links,
    loading: state => state.loading,
    error: state => state.error,
    browseRecordStatus: state => state.browseRecordStatus,
    readRecordStatus: state => state.readRecordStatus,
    editRecordStatus: state => state.editRecordStatus,
    addRecordStatus: state => state.addRecordStatus,
    deleteRecordStatus: state => state.deleteRecordStatus,
    paginateRecordStatus: state => state.paginateRecordStatus,
    paginateDispositionsAvailableStatus: state => state.paginateDispositionsAvailableStatus,
    paginateScorecardsAvailableStatus: state => state.paginateScorecardsAvailableStatus
};

export const actions = {
    browseRecords({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_RECORD_STATUS", 1);
        RoleService.browseRecords(payload).then((response) => {
            setPaginatedRecords(commit, response);
            commit("SET_BROWSE_RECORD_STATUS", 2);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_BROWSE_RECORD_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },
    browseRoleDispositionsAvailable({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_RECORD_STATUS", 1);
        RoleService.browseRoleDispositionsAvailable(payload).then((response) => {
            setPaginatedDispositionsAvailable(commit, response);
            commit("SET_BROWSE_RECORD_STATUS", 2);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_BROWSE_RECORD_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },
    browseRoleDispositions({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_RECORD_STATUS", 1);
        RoleService.browseRoleDispositions(payload).then((response) => {
            setPaginatedRecords(commit, response);
            commit("SET_BROWSE_RECORD_STATUS", 2);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_BROWSE_RECORD_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },
    browseRoleScorecardsAvailable({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_RECORD_STATUS", 1);
        RoleService.browseRoleScorecardsAvailable(payload).then((response) => {
            setPaginatedScorecardsAvailable(commit, response);
            commit("SET_BROWSE_RECORD_STATUS", 2);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_BROWSE_RECORD_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },
    browseRoleScorecards({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_RECORD_STATUS", 1);
        RoleService.browseRoleScorecards(payload).then((response) => {
            setPaginatedRecords(commit, response);
            commit("SET_BROWSE_RECORD_STATUS", 2);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_BROWSE_RECORD_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },
    readRecord({ commit }, recordId) {
        commit("SET_ERROR", null);
        commit("SET_READ_RECORD_STATUS", 1);
        RoleService.readRecord(recordId).then((response) => {
            commit("SET_READ_RECORD_STATUS", 2);
            commit("SET_RECORD", response.data.data);
        }).catch((error) => {
            commit("SET_READ_RECORD_STATUS", 3);
            commit("SET_RECORD", null);
            commit("SET_ERROR", getError(error));
        });
    },
    editRecord({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_EDIT_RECORD_STATUS", 1);
        RoleService.editRecord(payload)
            .then((response) => {
                commit("SET_RECORD", response.data.data);
                commit("SET_EDIT_RECORD_STATUS", 2);
            })
            .catch((error) => {
                commit("SET_EDIT_RECORD_STATUS", 3);
                commit("SET_ERROR", getError(error));
            });
    },
    addRecord({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_ADD_RECORD_STATUS", 1);
        commit("SET_RECORD", null);

        return RoleService.addRecord(payload)
            .then((response) => {
                const recordData = response.data?.data || response.data;

                if (!recordData) {
                    throw new Error("No record data received from API");
                }

                commit("SET_RECORD", recordData);
                commit("SET_ADD_RECORD_STATUS", 2);
                return recordData;
            })
            .catch((error) => {
                commit("SET_ADD_RECORD_STATUS", 3);
                commit("SET_ERROR", getError(error));
                throw error;
            });
    },
    deleteRecord({ commit }, id) {
        commit("SET_ERROR", null);
        commit("SET_DELETE_RECORD_STATUS", 1);
        RoleService.deleteRecord(id)
            .then(() => {
                commit("SET_DELETE_RECORD_STATUS", 2);
            })
            .catch((error) => {
                commit("SET_DELETE_RECORD_STATUS", 3);
                commit("SET_ERROR", getError(error));
            });
    },

    paginateRecords({ commit }, link) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_PAGINATE_RECORD_STATUS", 1);
        RoleService.paginateRecords(link).then((response) => {
            commit("SET_PAGINATE_RECORD_STATUS", 2);
            setPaginatedRecords(commit, response);
        }).catch((error) => {
            commit("SET_PAGINATE_RECORD_STATUS", 3);
            commit("SET_LOADING", false);
            commit("SET_ERROR", getError(error));
        });
    },

    paginateDispositionsAvailable({ commit }, link) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_PAGINATE_DISPOSITIONS_AVAILABLE_STATUS", 1);
        RoleService.paginateDispositionsAvailable(link).then((response) => {
            commit("SET_PAGINATE_DISPOSITIONS_AVAILABLE_STATUS", 2);
            setPaginatedDispositionsAvailable(commit, response);
        }).catch((error) => {
            commit("SET_PAGINATE_DISPOSITIONS_AVAILABLE_STATUS", 3);
            commit("SET_LOADING", false);
            commit("SET_ERROR", getError(error));
        });
    },

    paginateScorecardsAvailable({ commit }, link) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_PAGINATE_SCORECARDS_AVAILABLE_STATUS", 1);
        RoleService.paginateScorecardsAvailable(link).then((response) => {
            commit("SET_PAGINATE_RECORDS_AVAILABLE_STATUS", 2);
            setPaginatedScorecardsAvailable(commit, response);
        }).catch((error) => {
            commit("SET_PAGINATE_SCORECARDS_AVAILABLE_STATUS", 3);
            commit("SET_LOADING", false);
            commit("SET_ERROR", getError(error));
        });
    },

    initRecords({ commit }) {
        commit("SET_RECORDS", []);
        commit("SET_RECORD", {});
        commit("SET_ROLE_DISPOSITIONS_AVAILABLE", []);
        commit("SET_ROLE_SCORECARDS_AVAILABLE", []);
        commit("SET_PAGINATE_RECORD_STATUS", 0);
        commit("SET_PAGINATE_DISPOSITIONS_AVAILABLE_STATUS", 0);
        commit("SET_PAGINATE_SCORECARDS_AVAILABLE_STATUS", 0);
        commit("SET_BROWSE_RECORD_STATUS", 0);
        commit("SET_READ_RECORD_STATUS", 0);
        commit("SET_EDIT_RECORD_STATUS", 0);
        commit("SET_DELETE_RECORD_STATUS", 0);
    },

    dropdownSelected({ commit, getters }, roleId) {
        let records = JSON.parse(JSON.stringify(getters["records"]));
        if (roleId !== null && roleId !== undefined) {
            if (!records.some(role => role.id === roleId)) {
                RoleService.readRecord(roleId).then((response) => {
                    records.push(response.data.data);
                });
            }
        }
        commit("SET_RECORDS", records);
    },
    
    async assignDispositionToRole({ commit }, { roleId, dispositionId }) {
        try {
            await RoleService.assignDispositionToRole(roleId, dispositionId);
            console.log("Assigned disposition to role!")
            commit("ASSIGN_DISPOSITION_TO_ROLE", { roleId, dispositionId });
        } catch (error) {
            console.error("Failed to assign disposition:", error);
        }
    },

    async removeDispositionFromRole({ commit }, { roleId, dispositionId }) {
      
        try {
          await RoleService.removeDispositionFromRole(roleId, dispositionId);
          commit("REMOVE_DISPOSITION_FROM_ROLE", { roleId, dispositionId });
        } catch (error) {
          console.error("Failed to remove disposition:", error);
        }
      },
      

    async assignScorecardToRole({ commit }, { roleId, scorecardId }) {
        try {
            await RoleService.assignScorecardToRole(roleId, scorecardId);
            console.log("Assigned scorecard to role!")
            commit("ASSIGN_SCORECARD_TO_ROLE", { roleId, scorecardId });
        } catch (error) {
            console.error("Failed to assign scorecard:", error);
        }
    },

    async removeScorecardFromRole({ commit }, { roleId, scorecardId }) {
        try {
            await RoleService.removeScorecardFromRole(roleId, scorecardId);
            commit("REMOVE_SCORECARD_FROM_ROLE", { roleId, scorecardId });
        } catch (error) {
            console.error("Failed to remove scorecard:", error);
        }
    }
};
