import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/scorecards',
        name: 'scorecards',
        redirect: '/scorecards/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Scorecard
        },
        component: ModelView,
        children: [
            {
                path: '',
                name: 'scorecards',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Scorecards',
                        }
                    ]
                },
                component: () => import('../../views/Scorecards/Index')
            },
            {
                path: "/scorecards/new",
                name: "newScorecard",
                meta: {
                    action: AUTH_HELPER.authActions.add,
                    breadcrumb: [
                        {
                            parent: 'Scorecards',
                            label: 'New',
                        }
                    ]
                },
                component: () => import('../../views/Scorecards/ScorecardNew'),
            },
            {
                path: '/scorecards/:scorecardId',
                name: 'scorecard',
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'Scorecards'
                        }
                    ]
                },
                component: () => import('../../views/Scorecards/Scorecard')
            },
            {
                path: "/scorecards/:scorecardId/edit",
                name: "editScorecard",
                component: () => import('../../views/Scorecards/ScorecardEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit,
                    breadcrumb: [
                        {
                            parent: 'Scorecards',
                            label: 'Edit',
                        }
                    ]
                },
            },
        ]
    }
];