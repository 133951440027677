import home from "./home";
import auth from "./auth";
import core from "./core";
import errors from "./errors";
import users from "./users";
import accounts from "./accounts";
import roles from "./roles";
import Instances from "./Instances";
import Chat from "./Chat";
import Action from "./Action";
import Settings from "./Settings";
import Reporting from "./Reportings";
import Vendors from "./Vendors";
import Contacts from "./Contacts";
import Feedback from "./Feedback";
import agents from "./agents";
import AgentInstructions from "./agent-instructions";
import tools from "./tools";
import llms from "./llms";
import bots from "./bots";
import dispositions from "./dispositions";
import scorecards from "./scorecards";

import authMiddleware from "../../middleware/auth";

var routes = [];
var allRoutes = [];
var applicationRoutes = {
    path: '/',
    component: () => import("../../app/components/AppWrapper"),
    meta: {
        middleware: [ authMiddleware ]
    },
    children: [],
};
var catchall = [
    {
        path: '/:catchall(.*)',
        name: 'notFound',
        component: () => import('../../views/Errors/NotFound')
    }
];
applicationRoutes.children = applicationRoutes.children.concat(core, accounts, users, roles, Instances, Action, Settings, Reporting, Chat, Contacts, Feedback, agents, AgentInstructions, tools, bots, llms, dispositions, scorecards);
var applicationRoutesArray = [];
applicationRoutesArray.push(applicationRoutes);
allRoutes = allRoutes.concat(home, applicationRoutesArray, auth, errors, Vendors);
routes = routes.concat(allRoutes, catchall);
export default routes;